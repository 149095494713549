import React, { useState, useEffect } from 'react';
import i18next from "i18next";
import "../../styles/GlobalComponents.css";
import { isPlatform } from '@ionic/react';

function HeaderComponent() {
    const [ios, setIos] = useState(true);

    useEffect(() => {
        if (isPlatform('ios')) {
            setIos(false);
        } else {
            setIos(true);
        }
    }, [])

    return (
        <div className="container h-100">
            <div className="container h-100">
                <div className="container-banderas">
                    <button type="button" className="banderas" onClick={() => i18next.changeLanguage('es')}>
                        <span>Castellano</span></button>
                    <button type="button" className="banderas" onClick={() => i18next.changeLanguage('pa')}>
                        <span>Euskera</span>
                    </button>
                </div>
            </div>
        </div>
    );

};

export default HeaderComponent;