import React from 'react';
import { withTranslation } from "react-i18next";
import { Doughnut, Chart } from 'react-chartjs-2';

const verde = process.env.REACT_APP_GRAFICO_CONSUMO_CIRCULO;
const azul = process.env.REACT_APP_GRAFICO_CONSUMO_CIRCULO;
const naranja = process.env.REACT_APP_GRAFICO_CONSUMO_CIRCULO;
let color = naranja;


var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
    draw: function () {
        originalDoughnutDraw.apply(this, arguments);

        var chart = this.chart.chart;
        var ctx = chart.ctx;
        var width = chart.width;
        var height = chart.height;
        ctx.font = "25px sans-serif";
        ctx.textBaseline = "middle";

        //TEXTO GB DISPONIBLES
        var text = chart.config.data.text,
            textX = Math.round((width - ctx.measureText(text).width) / 2.05),
            textY = height / 2;
        // TE QUEDAN
        var text1 = chart.config.data.text1,
            text1X = Math.round((width - ctx.measureText(text1).width) / 1.9),
            text1Y = height / 3;

        //TEXTO GB TIENES 
        var text2 = chart.config.data.text2,
            text2X = Math.round((width - ctx.measureText(text2).width) / 1.85),
            text2Y = height / 1.5;

        ctx.font = "25px sans-serif";
        ctx.fillStyle = process.env.REACT_APP_GRAFICO_CONSUMO_RESTANTE;
        ctx.fillText(text, textX, textY);
        ctx.font = "20px sans-serif";
        ctx.fillStyle = process.env.REACT_APP_GRAFICO_CONSUMO_LETRAS;
        ctx.fillText(text1, text1X, text1Y);
        ctx.fillText(text2, text2X, text2Y);
    }
});

class GraficoDonutTranslated extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        resto: 0,
        disponible: 0,
        color: naranja

    };

    componentDidMount() {
        this.renderizarGrafico();
        this.colorearGrafico();
    };

    componentDidUpdate(prevProps) {
        if (this.props.restante !== prevProps.restante) {
            this.renderizarGrafico();
            this.colorearGrafico();
        }
    }

    colorearGrafico = () => {
        if (this.props.medida == 'GB') {
            color = naranja;
            this.setState({ color: naranja });

        } else if (this.props.medida == 'MIN') {
            color = verde;
            this.setState({ color: verde });
        } else if (this.props.medida == 'SMS') {
            color = azul;
            this.setState({ color: azul });

        }


    }
    renderizarGrafico = () => {

        if (this.props.restante == 'Ilimitados' || this.props.total == 'Ilim.') {
            this.setState({ resto: 0, disponible: 100 })
        } else {
            let queda = (parseFloat(this.props.restante) * 100) / parseFloat(this.props.total);
            let gaste = 100 - queda;
            this.setState({ resto: gaste, disponible: queda })
        }

    }


    render() {
        const { t } = this.props;
        const data = {
            labels: ['Disponible', 'Consumido'],
            datasets: [
                {

                    data: [this.state.disponible, this.state.resto],
                    backgroundColor: [
                        this.state.color,
                    ],
                    borderColor: [
                        this.state.color,
                    ],
                    borderWidth: 1,
                },
            ],
            text: `${this.props.restante} ${this.props.medida}`,
            text1: 'Te quedan',
            text2: `de ${this.props.total} ${this.props.medida}`
        };

        const opciones = {
            cutoutPercentage: 90,
            legend: {
                display: false,
            },
            animation: {
                animationRotate: true,
                duration: 2000
            },
        }

        return (
            <React.Fragment>

                <div className="grafico-gb">
                    <Doughnut data={data} options={opciones} />
                </div>
            </React.Fragment>

        )
    }
}

const GraficoDonut = withTranslation('common')(GraficoDonutTranslated)
export default GraficoDonut;