import React from "react";
import PageLoading from "../../components/PantallaCarga/PageLoading";
import PageError from "../../components/PantallaCarga/PageError";
import MiniLoader from "../../components/PantallaCarga/MiniLoader";
import api from "../../components/servicios/api";
import { Bar } from 'react-chartjs-2';
import "../../styles/GlobalPages.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import { Keyboard } from '@capacitor/keyboard';
import { IonAlert } from '@ionic/react';
import info from '../../Imagenes/info.png';
import PiePagina from '../../components/Navbar/PiePagina';
import { isPlatform } from '@ionic/react';
import { Contacts } from '@capacitor-community/contacts';


let userConsumo;
let consumo = []
let consumo1;
let permiso = 0;
//1º - Vamos a obtener un array con los últimos 12 meses para mostrarlo en el gráfico
let f = new Date();
let arrayYear = [];
let arrayMonth = [];
let numero_llamadas = [];
let numero_minutos = [];
let arrayData = [];
let min = [];
let value;
let año;
let mes;



let d = new Date(f.getFullYear(), f.getMonth(), 1);

for (let i = 0; i < 12; i++) {
    arrayYear.push(d.getFullYear());
    arrayMonth.push(d.getMonth() + 1);
    d.setMonth(d.getMonth() - 1);
}
//2º - Recorremos el array de fechas y construimos los labels para el grafico
let arrayLabels = [];
for (let i = 0; i < arrayYear.length; i++) {
    arrayLabels.push(("0" + arrayMonth[i]).slice(-2) + '-' + arrayYear[i]);
}


class ConsumoMensualTranslated extends React.Component {

    state = {
        mostrar: true,
        loading: true,
        error: null,
        labelGraficoAmarillo: '',
        labelGraficoVerde: '',
        FechaLabel: '',
        llamadas: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        minutos: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        form: {
            telefono: '',
            tipo: '',
        },
        Fecha: '',
        TipoLlamada: '',
        modal: false,
        detalle: '',
        alert: false

    };


    componentDidMount() {
        this.fetchData();
        if (isPlatform('ios') && !isPlatform('mobileweb')) {
            Keyboard.setAccessoryBarVisible({ isVisible: true });
        }
        if (isPlatform('android') && !isPlatform('mobileweb') || isPlatform('ios') && !isPlatform('mobileweb')) {
            // this.obtenerContactos();
        }
    }
    abrirAlert = () => {
        this.setState({ alert: !this.state.alert });
    }

    obtenerContactos = async () => {
        await Contacts.getPermissions().then((res) => {
            if (res.granted == true || res.contacts == 'granted') {
                permiso = 1;
            } else {
                console.log('no concedio permisos');
            }
        });
    }



    fetchData = async () => {
        this.setState({ loading: true, error: null });
        userConsumo = undefined;

        try {
            const cliente = JSON.parse(localStorage.getItem('user'));
            await api.Clientes.telefonos(cliente.usuario).then(
                ({ data }) => (userConsumo = data)
            ).catch(function (err) {
                console.log(err);
            });
            if (!Array.isArray(userConsumo)) {
                this.abrirAlert();
                this.setState({ loading: false, error: true });
            } else {
                this.setState({ loading: false, datos: userConsumo });
            }
        } catch (error) {
            this.setState({ loading: false, error: error });
        }
    };

    consultaCdr = async () => {

        if (this.state.form.telefono != '' && this.state.form.tipo != '') {
            this.setState({ loading: true, error: null });
            try {
                for (let i = 0; i < arrayYear.length; i++) {
                    await api.Clientes.consultaCdr(this.state.form.telefono, arrayMonth[i], arrayYear[i], this.state.form.tipo).then(
                        ({ data }) => (consumo1 = data),
                    );
                    if (Array.isArray(consumo1)) {
                        consumo.push(consumo1)
                    } else if (consumo1 === "No se han encontrado datos para el modelo <b>resumen_cdr</b>") {
                        let info = {
                            'mes': arrayMonth[i],
                            'ano': arrayYear[i],
                            'numero_llamadas': 0
                        }
                        consumo.push(info)
                    } else {
                        this.setState({ loading: false, error: true });
                    }

                }

                for (let j = 0; j < consumo.length; j++) {
                    numero_llamadas[j] = 0;
                    numero_minutos[j] = 0;

                    if (consumo[j].numero_llamadas === undefined) {

                        let numero_llamadas_k = 0;
                        let numero_minutos_k = 0;

                        for (let k = 0; k < consumo[j].length; k++) {
                            numero_llamadas_k = numero_llamadas_k + parseInt(consumo[j][k].numero_llamadas);
                            numero_minutos_k = numero_minutos_k + parseInt(consumo[j][k].duracion);
                        }

                        numero_llamadas[j] = numero_llamadas[j] + numero_llamadas_k;
                        numero_minutos[j] = numero_minutos[j] + numero_minutos_k;
                    } else {
                        numero_llamadas[j] = numero_llamadas[j] + parseInt(consumo[j].numero_llamadas);
                        numero_minutos[j] = numero_minutos[j] + parseInt(consumo[j].duracion);
                    }

                    for (let z = 0; z < arrayYear.length; z++) {
                        if (arrayMonth[z] == consumo[j].mes && arrayYear[z] == consumo[j].ano && arrayMonth[z] != 'undefined' && arrayYear[z] != 'undefined') {
                            arrayData[z] = parseFloat(numero_llamadas).toFixed(2);
                        }
                    }
                }

                for (let u = 0; u < numero_minutos.length; u++) {
                    if (isNaN(parseInt(numero_minutos[u]))) {
                        min.push(0);
                    } else {
                        if (this.state.form.tipo == 3) {
                            min.push(parseFloat(
                                ((numero_minutos[u]) / 1024 / 1024 / 1024)).toFixed(2));
                            numero_llamadas = [];
                        } else {
                            min.push(parseFloat(
                                ((numero_minutos[u]) / 60)).toFixed(2));
                        }
                    }
                }


                this.setState({ loading: false, llamadas: numero_llamadas, minutos: min });

                numero_minutos = [];
                numero_llamadas = [];
                consumo = [];
                min = [];
            } catch (error) {

            }
        }
    };

    handleChange = async (e) => {
        await this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            },
        });
        if (this.state.form.tipo === '1') {
            this.state.labelGraficoVerde = 'Nª Llamadas realizadas';
            this.state.labelGraficoAmarillo = 'Min';

        }
        if (this.state.form.tipo === '2') {
            this.state.labelGraficoVerde = 'SMS';
            this.state.labelGraficoAmarillo = ' ';

        }
        if (this.state.form.tipo === '3') {
            this.state.labelGraficoVerde = ' ';
            this.state.labelGraficoAmarillo = 'GB';

        }
        this.consultaCdr();
    };

    fetchDataDetalle = async (mes, año) => {
        this.setState({ loading: true, error: null });
        try {
            mes = [];
            año = [];
            this.setState({ loading: false, detalle: value });

        } catch (error) {
            this.setState({ loading: false, error: true });
        }
    };

    render() {
        const opciones = {
            maintainAspectRatio: false,
            responsive: true,
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: process.env.REACT_APP_CONSUMO_EJES,
                    }
                }],
                yAxes: [{
                    ticks: {
                        fontColor: process.env.REACT_APP_CONSUMO_EJES,
                    }
                }]
            },
            legend: {
                labels: {
                    fontColor: process.env.REACT_APP_CONSUMO_EJES,
                }
            }
        }


        const grafico = () => ({
            labels: arrayLabels,
            datasets: [
                {
                    label: this.state.labelGraficoAmarillo,
                    backgroundColor: process.env.REACT_APP_CONSUMO_1,
                    borderColor: process.env.REACT_APP_CONSUMO_1,
                    borderWidth: 1,
                    hoverBackgroundColor: process.env.REACT_APP_CONSUMO_3,
                    hoverBorderColor: '#000000',
                    barThickness: 12,
                    data: this.state.minutos
                },
                {
                    label: this.state.labelGraficoVerde,
                    backgroundColor: process.env.REACT_APP_CONSUMO_2,
                    borderColor: process.env.REACT_APP_CONSUMO_2,
                    borderWidth: 1,
                    hoverBackgroundColor: process.env.REACT_APP_CONSUMO_4,
                    hoverBorderColor: '#000000',
                    barThickness: 12,
                    data: this.state.llamadas
                }
            ]
        })
        const data = grafico()



        const getElementAtEvent = async element => {
            if (!element.length) return

            const { _datasetIndex: datasetIndex, _index: index } = element[0]
            await this.setState({ FechaLabel: `Fecha : ` });
            await this.setState({ Fecha: `${data.labels[index]}` });
            this.setState({ TipoLlamada: ` : ${data.datasets[datasetIndex].data[index]}`, mostrar: false });
            let fecha = this.state.Fecha.split('-');
            año = fecha[1];
            mes = fecha[0];
            this.fetchDataDetalle(mes, año);


        }
        const getDatasetAtEvent = dataset => {
            if (!dataset.length) return
            const datasetIndex = dataset[0]._datasetIndex

            this.setState({ clickedDataset: `${data.datasets[datasetIndex].label} ` });
        }


        if (this.state.loading === true && !this.state.datos) {
            return <PageLoading />;
        }
        const { t } = this.props;
        if (this.state.error) {
            return (<div>
                <PageError />
                <IonAlert
                    isOpen={this.state.alert}
                    cssClass='error'
                    header={t('Consumo.noTel',)}
                    message={`<img src="${info}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                        handler: () => {
                            window.location = '/Home';
                        }
                    }]} />
            </div>
            )
        }

        return (

            <div className="container h-100">
                {this.props.error && (<p className="text-danger">{this.props.error.message}</p>)}
                <div className="consumo-listado">
                    <div className="select-telefonos">
                        <span className="label-telf">{t('Consumo.info',)}</span>
                        <select name="telefono" onChange={this.handleChange} className="select-tlf" >
                            <option key="0" value="" disabled selected>{t('Consumo.numero',)}</option>
                            {this.state.datos.map((dato) =>
                                <option key={dato.id} value={dato}>{dato}</option>
                            )}
                        </select>
                        <select name="tipo" onChange={this.handleChange} className="select-tipo">
                            <option key="0" value="" disabled selected>{t('Consumo.tipo',)}</option>
                            <option key="1" value="1">{t('Consumo.llamadas',)}</option>
                            <option key="2" value="2">{t('Consumo.sms',)}</option>
                            <option key="3" value="3">{t('Consumo.datos',)}</option>
                        </select>
                    </div>
                    <div className={this.state.loading ? 'hidden' : 'grafico'} style={{ width: '100%', height: '300px' }}>
                        <Bar data={grafico}
                            options={opciones}
                            getDatasetAtEvent={getDatasetAtEvent}
                            getElementAtEvent={getElementAtEvent}
                            onClick={this.handleChange}
                        />
                    </div>{this.state.loading && <MiniLoader color="#28a745" />}
                    <div className='detalles-grafico'>
                        <div className={this.state.mostrar ? '' : 'hidden'}>
                            <span>{this.state.Informacion}</span>
                            <span>{this.state.Informacion2}</span>
                        </div>
                        <div className="detalles-grafico-accion">
                            <br></br>
                            <span className={this.state.mostrar ? 'btn-cerrar' : 'hidden'}>{t('Consumo.infoGrafico',)}</span>
                            <Link
                                to={{
                                    pathname: `/ConsumoDetalle/`,
                                    state: {
                                        año: `${año}`,
                                        mes: `${mes}`,
                                        telefono: `${this.state.form.telefono}`,
                                        tipo: `${this.state.form.tipo}`,
                                        detalle: `${this.state.detalle}`,
                                        permiso: `${permiso}`
                                    }
                                }}>
                                <div className="etiquetas-grafico">
                                    <span className={this.state.mostrar ? 'hidden' : 'btn-cerrar'}>{this.state.FechaLabel}{this.state.Fecha}</span>
                                    <br></br>
                                    <span className={this.state.mostrar ? 'hidden' : 'btn-cerrar'}> {this.state.clickedDataset}{this.state.TipoLlamada}  </span>
                                </div>
                                <div className={this.state.mostrar ? 'hidden' : 'btn-cerrar'}>
                                    <FontAwesomeIcon icon="magnifying-glass" className="icon-btn" />
                                    <span className="">{t('Button.detalle',)}</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <PiePagina />
                <IonAlert
                    isOpen={this.state.alert}
                    cssClass='error'
                    header={t('Consumo.noTel',)}
                    message={`<img src="${info}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                        handler: () => {
                            window.location = '/Home';
                        }
                    }]}
                />
            </div>
        );
    }
}
const ConsumoMensual = withTranslation('common')(ConsumoMensualTranslated)
export default ConsumoMensual;